/*
 * @FilePath: index.js
 * @Author: xufei
 * @Date: 2023-12-13 16:31:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-12-22 11:16:53
 * Copyright: 2023 xxxTech CO.,LTD. All Rights Reserved.
 * @Descripttion: 
 */
import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('../views/AboutView.vue')
  // },
  {
    path: '/route',
    name: 'route',
    component: () => import('../views/route.vue')
  },
  {
    path: '/comment',
    name: 'comment',
    component: () => import('../views/comment.vue')
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('../views/information.vue')
  },
  {
    path: '/consultant',
    name: 'consultant',
    component: () => import('../views/consultant.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/informationContent',
    name: 'informationContent',
    component: () => import('../views/informationContent.vue')
  },
  {
    path: '/routeDetail',
    name: 'routeDetail',
    component: () => import('../views/routeDetail.vue')
  }, {
    path: '/introduction',
    name: 'introduction',
    component: () => import('../views/introduction.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
