/*
 * @FilePath: main.js
 * @Author: xufei
 * @Date: 2023-12-13 16:31:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-26 08:48:50
 * Copyright: 2023 xxxTech CO.,LTD. All Rights Reserved.
 * @Descripttion: 
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import sessionStoragePlugin from '@/utils/js/sessionStoragePlugin';

// const BASE_URL = 'http://lvyou.pro.aisisoft.com.cn'
const BASE_URL = 'https://api.godingzhi.com'
const Title =JSON.parse(sessionStorage.getItem('config'))?JSON.parse(sessionStorage.getItem('config'))[11].value: '北京金色国际旅行社有限公司-全球商旅服务商|旅游定制|商务展会|签证服务' 



createApp(App).use(store).use(router).provide('BASE_URL', BASE_URL).provide('Title', Title).use(ElementPlus, {
    locale: zhCn,
}).use(sessionStoragePlugin).mount('#app')
