/*
 * @FilePath: index.js
 * @Author: xufei
 * @Date: 2023-12-15 15:21:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-08 10:09:03
 * Copyright: 2023 xxxTech CO.,LTD. All Rights Reserved.
 * @Descripttion: 
 */
import axios from "@/request";




// 获取banner
export const getBanner = (data) => axios.get('/api/Banner/getBanner', data)

// 获取配置接口
export const getConfig = (data) => axios.post('/api/Banner/getConfig', data)

// 首页旅游咨询接口
export const getInformation = (data) => axios.post('/api/Banner/getInformation', data)

// 资讯详情接口
export const informationDetail = (data) => axios.post('/api/Banner/informationDetail', data)

// 客户点评接口
export const customComment = (data) => axios.get('/api/Banner/customComment', data)

// 旅行顾问接口
export const getConsultant = (data) => axios.get('/api/Banner/getConsultant', data)

// 客户点评列表接口
export const getPageCustomComment = (data) => axios.get('/api/Banner/getPageCustomComment', data)

// 资讯列表接口
export const getPageInformation = (data) => axios.get('/api/Banner/getPageInformation', data)

// 顾问列表接口
export const getPageConsultant = (data) => axios.get('/api/Banner/getPageConsultant', data)

// 首页旅游路线接口
export const getHomeRoute = (data) => axios.get('/api/Banner/getHomeRoute', data)

// 旅游路线接口
export const getHomeRoutePage = (data) => axios.get('/api/Banner/getHomeRoutePage', data)


// 旅游路线详情接口
export const routeDetail = (data) => axios.post('/api/Banner/routeDetail', data)

// 全部地区
export const getAreaList = (data) => axios.post('/api/Banner/getAreaList', data)