<template>
  <div>
    <div id="header">
      <div class="logo">
        <router-link to="/"></router-link>
      </div>
      <div class="sp_nav"><span></span><span></span><span></span></div>
      <div class="service iconfont">
        <a href="tel:+8613801833300">&#xe895;</a>
      </div>
      <div id="topnav" class="f16">
        <ul>
          <!--一级菜单-->
          <li>
            <router-link to="/route">旅行线路</router-link>
            <ul>
              <div class="one">
                <!--二级菜单-->
                <div class="two f14" v-for="item in areaList" :key="item.id" v-show="item.children.length">
                  <div  >
                   
                    <template v-if="item.children.length">
                      <b v-if="item.children.length" style="cursor: pointer;"
                        ><a
                         style="color: #000; !important"
                          class=""
                          >{{ item.mainland }}</a
                        >
                      </b>
                      <template v-if="item.children.length">
                        <!--三级菜单-->
                        <div
                          class="three"
                          v-for="sub in item.children"
                          :key="sub.id"
                        >
                          <a
                            @click="
                              router.push({
                                path: '/introduction',
                                query: {
                                  id: sub.id,
                                  area: sub.area,
                                },
                              })
                            "
                            >{{ sub.area }}</a
                          >
                        </div>
                      </template>
                    </template>
                  </div>

                  <!--三级菜单-->
                  <div class="clear"></div>
                </div>
                <!--二级菜单-->
                <div class="clear"></div>
              </div>
            </ul>
          </li>

          <li>
            <router-link to="/comment">客户点评</router-link>
          
          </li>
          <li>
            <router-link to="/information">旅游资讯</router-link>
            
          </li>
          <li>
            <router-link to="/consultant">旅行顾问</router-link>
          </li>
         
          <!--一级菜单-->
        </ul>
      </div>
      <div class="tel">
        <div class="iconfont phone s6">&#xe895;</div>
        <div class="no">
          <div class="xt s7">客户服务热线</div>
          <div class="xt s5">
            <a>{{ config[8] ? config[8].value : "" }}</a>
          </div>
        </div>
      </div>
    </div>

    <!--移动端弹出菜单-->
    <div class="sjj_nav">
      <ul>
        <!--一级菜单-->
        <li>
          <div class="one">
            <router-link to="/route">旅行线路</router-link>
          </div>
          <ul>
            <!--二级菜单-->
            <!-- <div class="two">
              <b><a href="2/index.htm" class="">欧洲</a></b>
              <div class="clear"></div>
            </div>
         
            <div class="three">
              <a href="18/index.htm" class="">北 欧</a>
            </div>
            <div class="three">
              <a href="33/index.htm" class="">南 欧</a>
            </div>
            <div class="three">
              <a href="85/index.htm" class="">中 欧</a>
            </div>
            <div class="three">
              <a href="87/index.htm" class="">西 欧</a>
            </div> -->
              <!--二级菜单-->
              <div class="two f14">
                  <div v-for="item in areaList" :key="item.id">
                    <template v-if="item.children">
                     <a  v-if="item.children.length" style="cursor: pointer;"
                          @click="
                            router.push({
                              path: '/route',
                              query: {
                                id: item.id,
                                mainland: item.mainland,
                              },
                            })
                          "
                          class=""
                          >{{ item.mainland }}</a
                        >
                      <template v-if="item.children.length">
                        <!--三级菜单-->
                        <div
                          class="three"
                          v-for="sub in item.children"
                          :key="sub.id"
                        >
                          <a
                            @click="
                              router.push({
                                path: '/introduction',
                                query: {
                                  id: sub.id,
                                  area: sub.area,
                                },
                              })
                            "
                            >{{ sub.area }}</a
                          >
                        </div>
                      </template>
                    </template>
                  </div>

                  <!--三级菜单-->
                  <div class="clear"></div>
                </div>
            <!--三级菜单-->
            <div class="clear"></div>
            <!--二级菜单-->
          </ul>
        </li>
        <li>
          <div class="one"></div>
          <ul>
            <!--二级菜单-->
            <div class="two">
              <div class="clear"></div>
            </div>
            <!--三级菜单-->
            <!--三级菜单-->
            <div class="clear"></div>
            <div class="two">
              <div class="clear"></div>
            </div>
            <!--三级菜单-->
            <!--三级菜单-->
            <div class="clear"></div>
            <div class="two">
              <div class="clear"></div>
            </div>
            <!--三级菜单-->
            <!--三级菜单-->
            <div class="clear"></div>
            <div class="two">
              <div class="clear"></div>
            </div>
            <!--三级菜单-->
            <!--三级菜单-->
            <div class="clear"></div>
            <!--二级菜单-->
          </ul>
        </li>
        <li>
          <div class="one">
            <router-link to="/comment">客户点评</router-link>
          </div>
          <ul>
            <!--二级菜单-->
            <!--二级菜单-->
          </ul>
        </li>
        <li>
          <div class="one">
            <router-link to="/information">旅游资讯</router-link>
          </div>
          <ul>
            <!--二级菜单-->
            <!--二级菜单-->
          </ul>
        </li>
        <li>
          <div class="one">
            <router-link to="/consultant">旅行顾问</router-link>
          </div>
          <ul>
            <!--二级菜单-->
            <!--二级菜单-->
          </ul>
        </li>
        <li>
          <div class="one"></div>
          <ul>
            <!--二级菜单-->
            <div class="two">
              <div class="clear"></div>
            </div>
            <!--三级菜单-->
            <!--三级菜单-->
            <div class="clear"></div>
            <!--二级菜单-->
          </ul>
        </li>
        <li>
          <div class="one"></div>
          <ul>
            <!--二级菜单-->
            <div class="two">
              <div class="clear"></div>
            </div>
            <!--三级菜单-->
            <!--三级菜单-->
            <div class="clear"></div>
            <div class="two">
              <div class="clear"></div>
            </div>
            <!--三级菜单-->
            <!--三级菜单-->
            <div class="clear"></div>
            <div class="two">
              <div class="clear"></div>
            </div>
            <!--三级菜单-->
            <!--三级菜单-->
            <div class="clear"></div>
            <div class="two">
              <div class="clear"></div>
            </div>
            <!--三级菜单-->
            <!--三级菜单-->
            <div class="clear"></div>
            <div class="two">
              <div class="clear"></div>
            </div>
            <!--三级菜单-->
            <!--三级菜单-->
            <div class="clear"></div>
            <!--二级菜单-->
          </ul>
        </li>
        <!--一级菜单-->
      </ul>
    </div>
    <!--移动端弹出菜单-->
  </div>
</template>

<script setup>
import { getConfig, getAreaList } from "@/api/index";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

// 路由实例化
const router = useRouter();
const config = ref([]);
const areaList = ref([]);
onMounted(() => {
  //   console.log(sessionStorage.getItem("config"));
  getConfigs();
  getAreaLists();
});
function getConfigs() {
  getConfig().then((res) => {
    // console.log(res);
    config.value = res.data;
    // console.log(JSON.stringify(res.data));
    sessionStorage.setItem("config", JSON.stringify(res.data));
    window.document.title = config.value[14].value;
  });
}
function getAreaLists() {
  getAreaList().then((res) => {
    areaList.value = res.data;
  });
}
</script>


<style lang="scss" scoped>
#header .logo a {
  background: url("@/assets/logo.jpg") no-repeat;
  width: 240px;
  height: 62px;
  display: block;
  background-size: 100%;
}
@media screen and (min-width: 1026px) {
  #header .logo a {
    background: url("@/assets/logo.jpg") no-repeat;
    width: 240px;
    height: 62px;
    display: block;
    background-size: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1025px) {
  #header .logo a {
    background: url("@/assets/logo.jpg") no-repeat;
    width: 160px;
    height: 42px;
    display: block;
    background-size: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  #header .logo a {
    background: url("@/assets/logo.jpg") no-repeat;
    width: 114px;
    height: 30px;
    display: block;
    background-size: 100%;
  }
}
@media screen and (min-width: 1px) and (max-width: 480px) {
  #header .logo a {
    background: url("@/assets/logo.jpg") no-repeat;
    width: 114px;
    height: 30px;
    display: block;
    background-size: 100%;
  }
}
</style>
