/*
 * @FilePath: se.js
 * @Author: xufei
 * @Date: 2023-12-18 16:21:10
 * @LastEditors: 
 * @LastEditTime: 2023-12-18 16:21:14
 * Copyright: 2023 xxxTech CO.,LTD. All Rights Reserved.
 * @Descripttion: 
 */
// sessionStoragePlugin.js
export default {
    install: (app) => {
      app.config.globalProperties.$mySessionData = sessionStorage.getItem('config');
    }
  };