<!--
 * @FilePath: App.vue
 * @Author: xufei
 * @Date: 2023-12-13 16:31:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-20 10:50:14
 * Copyright: 2023 xxxTech CO.,LTD. All Rights Reserved.
 * @Descripttion: 
-->
<template>
  <Navigation></Navigation>
  <router-view />
  <!-- <Footer></Footer> -->
</template>
<script setup>
import Navigation from "@/components/Navigation";
import { ref, watchEffect ,inject} from 'vue';
import { useRouter } from 'vue-router';
const Title= inject('Title')

// 使用 useRouter() 获取路由实例
const router = useRouter();

// 定义一个响应式变量来保存当前页面路径
const currentPath = ref('');

// 使用 watchEffect 监听当前页面路径的变化
watchEffect(() => {
  currentPath.value = router.currentRoute.value.path;
  if(currentPath.value!='/informationContent'||currentPath.value!='/routeDetail'){
    document.title=Title
  }
});
/*当网页正文高度小于可视窗口高度时，为footer添加类fixed-bottom*/
$(function () {
  function footerPosition() {
    setTimeout(function () {
      $("#footer").removeClass("fixed-bottom");
      var contentHeight = document.body.scrollHeight, //网页正文全文高度
        winHeight = window.innerHeight; //可视窗口高度，不包括浏览器顶部工具栏
      // console.log(contentHeight,'>', winHeight,contentHeight > winHeight);
      // if (!(contentHeight > winHeight)) {
      if (contentHeight < winHeight) {

        // console.log('添加fixed');
        //当网页正文高度小于可视窗口高度时，为footer添加类fixed-bottom
        setTimeout(function () {
          $("#footer").addClass("fixed-bottom");
        }, 100);
      } else {
        // console.log('删除fixed');
        setTimeout(function () {
          $("#footer").removeClass("fixed-bottom");
        }, 100);
      }
    }, 500);
  }
  footerPosition();
  $(window).resize(footerPosition);
});
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
